<template>
  <div>
    <v-row align="center" justify="center">
      <v-col class="col-banner">
        <v-img src="@/assets/consultancy.jpg" alt="Banner"></v-img>
      </v-col>
    </v-row>
    <h1 class="center blue--text pa-3">CONSULTORÍA DE SOFTWARE</h1>
    <v-row align="center" justify="center">
      <v-col cols="6">
        Nuestros consultores trabajan con usted implementando rápidamente la
        solución correcta, identifican oportunidades de mejora, analizan
        problemas del sistema, resuelven requerimientos del negocio,
        proporcionan valor a los sistemas que usted ya tiene le ayudan a manejar
        el cambio.
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="4">
        <v-card>
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-cogs</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Categorías y Subcategorías
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Organiza tus productos por categorías y subcategorías.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-cogs</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Gestiona tu inventario</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Gestiona la cantidad de productos disponibles a la venta.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-cogs</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">Opciones detalladas</h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Puedes detallar tu producto al máximo, agregarle opciones, fecha de
            disponibilidad, tamaños, colores, peso, fabricantes, filtros,
            ofertas
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            <v-icon class="mx-auto" x-large>fas fa-cogs</v-icon>
          </v-card-title>

          <v-card-title>
            <h3 class="align-center mx-auto pb-2">
              Sistema de puntos por compras
            </h3>
          </v-card-title>

          <v-card-subtitle class="text-justify">
            Tus clientes pueden recibir puntos por las compras realizadas en tu
            plataforma.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Ecommerce",
  components: {},
  data() {
    return {};
  }
};
</script>
